// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedLead: null
}

const physicalReview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return {
        ...state, 
        allData: action.data ? action.data : 0,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_REVIEW':
      return { ...state, selectedLead : action.selectedLead }
    case 'ADD_REVIEW':
      return { ...state }
    case 'DELETE_REVIEW':
      return { ...state }
    default:
      return { ...state }
  }
}
export default physicalReview

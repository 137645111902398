// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  open: 0,
  close: 0,
  followUps: [],
  followCount: 0,
  params: {},
  selectedLead: null,
  followUpsDates: {},
};

const leads = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return {
        ...state,
        allData: action.data ? action.data : 0,
        total: action.totalPages,
        params: action.params,
        open:
          action.data &&
          action.data.filter((val) => val.status == "open").length,
        close:
          action.data &&
          action.data.filter((val) => val.status == "closed").length,
      };
    case "GET_Followups":
      return {
        ...state,
        followUps: action.data,
        followCount: action.data.reduce((a,b)=> {return a+b.count},0),
        followUpsDates: action.followUpsDates,
      };
    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_LEAD":
      return { ...state, selectedLead: action.selectedLead };
    case "ADD_LEAD":
      return { ...state };
    case "DELETE_LEAD":
      return { ...state };
    default:
      return { ...state };
  }
};
export default leads;

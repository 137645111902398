// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import users from './users'
import navbar from './navbar'
import leads from './leads'
import reviews from './reviews'
import deliveryReview from './deliveryReview'
import physicalReview from './physicalReview'
import layout from './layout'
import dashboard from './dashboard'

const rootReducer = combineReducers({
  auth,
  users,
  navbar,
  leads,
  reviews,
  dashboard,
  layout,
  deliveryReview,
  physicalReview
})

export default rootReducer

import moment from "moment";
import {
  Music,
  Users,
  MapPin,
  Package,
  Star,
  TrendingUp,
  TrendingDown,
  Compass,
  Coffee,
} from "react-feather";
import { setCookie, getCookie, deleteCookie } from "./cookies";
import {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
} from "./localStorage";

//dates
export const todayDate = moment().format();
export const formattedTodayWithMoment = (date) => {
  if (date) return moment(date).format("YYYY-MM-DD");
  else return moment().format("YYYY-MM-DD");
};
export const startFormattedDate = moment().format("YYYY-MM-DD");
export const endFormattedDate = moment().add(7, "d").format("YYYY-MM-DD");
export const firstdayMonth = moment().format("YYYY-MM-01");
export const startWithMonth = moment()
  .subtract(1, "months")
  .format("YYYY-MM-DD");
export const weekStartDate = moment()
  .clone()
  .startOf("isoWeek")
  .format("YYYY-MM-DD");
export const monthStartDate = moment().format("YYYY-MM-01");
export const yearStartDate = moment().format("YYYY-01-01");

export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};
export const formatDateWithTime = (
  value,
  formatting = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};
// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (getCookie("accessToken") && getLocalStorage("userData")) {
    // let userDetails = {
    //   roles: getCookie('authRoles'),
    //   user: getLocalStorage('authUser'),
    // };
    return getLocalStorage("userData");
  } else {
    return false;
  }
};
//localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin" && userRole === "maintainer") return "/";
  if (userRole === "user") {
    var org = getLocalStorage("userData").organization;
    switch (org) {
      case "radix":
        return "/reviews/radix";
      case "anardana":
        return "/reviews/anardana/online";
      case "woodapple":
        return "/reviews/woodapple";
      case "relp":
        return "/reviews/relp";
      default:
        return "/";
    }
  }
  // return '/access-control'
  return "/login";
};

export const roleAbility = [
  {
    role: "admin",
    ability: [
      {
        action: "manage",
        subject: "all",
      },
    ],
  },
  {
    role: "maintainer",
    ability: [
      {
        action: "manage",
        subject: "all",
      },
    ],
  },
  {
    role: "user",
    type: [
      {
        organization: "radix",
        ability: [
          {
            action: "read",
            subject: "radix",
          },
        ],
      },
      {
        organization: "nebulahq",
        ability: [
          {
            action: "read",
            subject: "nebulahq",
          },
        ],
      },
      {
        organization: "anardana",
        ability: [
          {
            action: "read",
            subject: "anardana",
          },
        ],
      },
    ],
  },
];
// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const feedbackOptions = [
  { label: "Friends", value: "Friends" },
  { label: "Google", value: "Google" },
  { label: "Social Media", value: "Social Media" },
  { label: "Zomato ", value: "Zomato" },
  { label: "Swiggy ", value: "Swiggy" },
  { label: "Dineout ", value: "Dineout" },
  { label: "Easydiner ", value: "Easydiner" },
  { label: "Magicpin", value: "Magicpin" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "SMS", value: "SMS" },
  { label: "Others ", value: "Others" },
];
export const priorityOptions = [
  { label: "Hot", value: "hot" },
  { label: "Neutral", value: "neutral" },
  { label: "Cold", value: "cold" },
];

export const PaymentModes = [
  { label: "Panel Payment", value: "panelPayment" },
  { label: "TPA Payment", value: "tpaPayment" },
  { label: "Cash Payment", value: "cashPayment" },
  { label: "Credit Card", value: "creditCard" },
];
export const leadOptions = [
  { label: "Open", value: "open" },
  { label: "Close", value: "closed" },
];

export const dateFilterOptions = [
  { text: "WEEKLY", value: "week" },
  { text: "MONTHLY", value: "month" },
  { text: "YEARLY", value: "year" },
];

export const departmentOptions = [
  { label: "Anesthesia", value: "Anesthesia" },
  { label: "Cardiology", value: "Cardiology" },
  { label: "Cosmo Dental", value: "Cosmo Dental" },
  { label: "Dermatology", value: "Dermatology" },
  { label: "Dialysis", value: "Dialysis" },
  { label: "ENT", value: "ENT" },
  { label: "Gastroenterology", value: "Gastroenterology" },
  { label: "Gynaecology & Obstetrics", value: "Gynaecology & Obstetrics" },
  { label: "Medicine/ Physician", value: "Medicine/ Physician" },
  { label: "Nephrology", value: "Nephrology" },
  { label: "Neurology", value: "Neurology" },
  { label: "Neurosciences", value: "Neurosciences" },
  { label: "Nutritionist", value: "Nutritionist" },
  {
    label: "Ophthalmology/Eye Specialist",
    value: "Ophthalmology/Eye Specialist",
  },
  {
    label: "Orthopaedics/ Bones Specialist",
    value: "Orthopaedics/ Bones Specialist",
  },
  { label: "Paediatric", value: "Paediatric" },
  { label: "Orthopaedics", value: "Orthopaedics" },
  { label: "Pychiatric", value: "Pychiatric" },
  { label: "Sugar", value: "Sugar" },
  { label: "Paediatric Ortho", value: "Paediatric Ortho" },
  { label: "Paediatric Gastro", value: "Paediatric Gastro" },
  { label: "Paediatric Surgeon", value: "Paediatric Surgeon" },
  { label: "Pain Management", value: "Pain Management" },
  { label: "Pathology", value: "Pathology" },
  { label: "Pharmacy", value: "Pharmacy" },
  { label: "Physiotherapy", value: "Physiotherapy" },
  { label: "Psychiatry", value: "Psychiatry" },
  {
    label: "Pulmonology/Chest Specialists",
    value: "Pulmonology/Chest Specialists",
  },
  { label: "Radiology", value: "Radiology" },
  { label: "Surgery", value: "Surgery" },
  { label: "Urology", value: "Urology" },
  { label: "Others", value: "Others" },
];

export const doctorOptions = [
  { label: "Dr. Ravi Malik", value: "Dr. Ravi Malik", dept: "Paediatric" },
  { label: "Dr. Ritu Jain", value: "Dr. Ritu Jain", dept: "Paediatric" },
  { label: "Dr. Harit", value: "Dr. Harit", dept: "Paediatric" },
  {
    label: "Dr. Ashok Goyal",
    value: "Dr. Ashok Goyal",
    dept: "Gastroenterology",
  },
  {
    label: "Dr. Samresh Mohan",
    value: "Dr. Samresh Mohan",
    dept: "Orthopaedics",
  },

  {
    label: "Dr. Ashish Gupta",
    value: "Dr. Ashish Gupta",
    dept: "Orthopaedics",
  },

  {
    label: "Dr. Sarika",
    value: "Dr. Sarika",
    dept: "Pychiatric",
  },
  {
    label: "Dr. Himanshu",
    value: "Dr. Himanshu",
    dept: "Sugar",
  },

  {
    label: "Dr S.k Mittal ",
    value: "Dr S.k Mittal ",
    dept: "Paediatric Gastro",
  },
  { label: "Dr.Pawan", value: "Dr. Pawan", dept: "Paediatric" },
  {
    label: "Dr. Dharmendra Singh",
    value: "Dr. Dharmendra Singh",
    dept: "Paediatric Surgeon",
  },
  { label: "Dr. AS Vasudev", value: "Dr. AS Vasudev", dept: "Paediatric" },
  {
    label: "Dr. Renu Malik",
    value: "Dr. Renu Malik",
    dept: "Gynaecology & Obstetrics",
  },
  {
    label: "Dr. Meena Malhotra",
    value: "Dr. Meena Malhotra",
    dept: "Gynaecology & Obstetrics",
  },
  { label: "Dr. Rajni", value: "Dr. Rajni", dept: "Gynaecology & Obstetrics" },
  {
    label: "Dr. Manju Gupta",
    value: "Dr. Manju Gupta",
    dept: "Gynaecology & Obstetrics",
  },
  {
    label: "Dr. Vaishali Saini",
    value: "Dr. Vaishali Saini",
    dept: "Gynaecology & Obstetrics",
  },
  {
    label: "Dr. Rupam Arora",
    value: "Dr. Rupam Arora",
    dept: "Gynaecology & Obstetrics",
  },
  {
    label: "Dr. Nupur Pagore",
    value: "Dr. Nupur Pagore",
    dept: "Gynaecology & Obstetrics",
  },
  {
    label: "Dr. Shruti Malik",
    value: "Dr. Shruti Malik",
    dept: "Cosmo Dental",
  },
  { label: "Dr. Rachit", value: "Dr. Rachit", dept: "Cosmo Dental" },
  { label: "Dr. Mohit", value: "Dr. Mohit", dept: "Cosmo Dental" },
  { label: "Dr. Shilpa Das", value: "Dr. Shilpa Das", dept: "Cosmo Dental" },
  { label: "Dr. Ajay Jain", value: "Dr. Ajay Jain", dept: "Surgery" },
  { label: "Dr. Deepak Saran", value: "Dr. Deepak Saran", dept: "Surgery" },
  { label: "Dr. GS Garg", value: "Dr. GS Garg", dept: "Surgery" },
  { label: "Dr. Pradeep Saini", value: "Dr. Pradeep Saini", dept: "Surgery" },
  { label: "Dr. GS Garg", value: "Dr. GS Garg", dept: "Surgery" },
  {
    label: "Dr. AS Talwar",
    value: "Dr. AS Talwar",
    dept: "Medicine/ Physician",
  },
  {
    label: "Dr. Sunny Uppal",
    value: "Dr. Sunny Uppal",
    dept: "Medicine/ Physician",
  },
  {
    label: "Dr. Surendra Rajpal",
    value: "Dr. Surendra Rajpal",
    dept: "Medicine/ Physician",
  },
  {
    label: "Dr. Athira PA",
    value: "Dr. Athira PA",
    dept: "Medicine/ Physician",
  },
  {
    label: "Dr. Amrit Singh Matharu",
    value: "Dr. Amrit Singh Matharu",
    dept: "Medicine/ Physician",
  },
  {
    label: "Dr. Vikas Bhardwaj",
    value: "Dr. Vikas Bhardwaj",
    dept: "Orthopaedics/ Bones Specialist",
  },
  {
    label: "Dr. K.C. Verma",
    value: "Dr. K.C. Verma",
    dept: "Orthopaedics/ Bones Specialist",
  },
  {
    label: "Dr. Prabhat Aggarwal",
    value: "Dr. Prabhat Aggarwal",
    dept: "Orthopaedics/ Bones Specialist",
  },
  {
    label: "Dr. KB Tiwari",
    value: "Dr. KB Tiwari",
    dept: "Orthopaedics/ Bones Specialist",
  },
  {
    label: "Dr. Apoorva Mehra",
    value: "Dr. Apoorva Mehra",
    dept: "Orthopaedics/ Bones Specialist",
  },
  {
    label: "Dr. Deepak Arora",
    value: "Dr. Deepak Arora",
    dept: "Orthopaedics/ Bones Specialist",
  },
  { label: "Dr. KK Sinha", value: "Dr. KK Sinha", dept: "Radiology" },
  { label: "Dr. TK Vohra", value: "Dr. TK Vohra", dept: "Radiology" },
  { label: "Dr. Ashish Tomar", value: "Dr. Ashish Tomar", dept: "Radiology" },
  {
    label: "Dr. Rajesh Malhotra",
    value: "Dr. Rajesh Malhotra",
    dept: "Radiology",
  },
  { label: "Dr. Reema Dahiya", value: "Dr. Reema Dahiya", dept: "Pathology" },
  {
    label: "Dr. Meenu Aggarwal",
    value: "Dr. Meenu Aggarwal",
    dept: "Pathology",
  },
  { label: "Dr. Amit Batra", value: "Dr. Amit Batra", dept: "Neurosciences" },
  { label: "Dr. Rahul Gupta", value: "Dr. Rahul Gupta", dept: "Neurosciences" },
  { label: "Dr. Abhinav", value: "Dr. Abhinav", dept: "Neurosciences" },
  { label: "Dr. Rajesh Dhall", value: "Dr. Rajesh Dhall", dept: "Anesthesia" },
  { label: "Dr. Rakesh Atrey", value: "Dr. Rakesh Atrey", dept: "Anesthesia" },
  { label: "Dr. RK Arora", value: "Dr. RK Arora", dept: "Anesthesia" },
  { label: "Dr. Swaraj Garg", value: "Dr. Swaraj Garg", dept: "Anesthesia" },
  { label: "Dr. Ashok Singh", value: "Dr. Ashok Singh", dept: "ENT" },
  { label: "Dr. Ajay Jain", value: "Dr. Ajay Jain", dept: "ENT" },
  {
    label: "Dr. Sharad Kumar Singhi",
    value: "Dr. Sharad Kumar Singhi",
    dept: "ENT",
  },
  {
    label: "Dr. Aditi Garg",
    value: "Dr. Aditi Garg",
    dept: "ENT"
  },
  { label: "Dr. Prateek Sharma", value: "Dr. Prateek Sharma", dept: "ENT" },
  { label: "Dr. SK Gupta", value: "Dr. SK Gupta", dept: "ENT" },
  { label: "Dr. A.K Lahri", value: "Dr. A.K Lahri", dept: "ENT" },
  {
    label: "Dr. Kirti Dwivedi",
    value: "Dr. Kirti Dwivedi",
    dept: "Physiotherapy",
  },
  { label: "Dr. Ravi Kumar", value: "Dr. Ravi Kumar", dept: "Physiotherapy" },
  {
    label: "Dr. Abhinav Bhanot",
    value: "Dr. Abhinav Bhanot",
    dept: "Pulmonology/Chest Specialists",
  },
  {
    label: "Dr. Nidhi Gupta",
    value: "Dr. Nidhi Gupta",
    dept: "Ophthalmology/Eye Specialist",
  },
  {
    label: "Dr. Himanshu Singh Pundir",
    value: "Dr. Himanshu Singh Pundir",
    dept: "Ophthalmology/Eye Specialist",
  },
  {
    label: "Dr. Nishant Tyagi",
    value: "Dr. Nishant Tyagi",
    dept: "Cardiology",
  },
  { label: "Dr. Mohit Sharma", value: "Dr. Mohit Sharma", dept: "Psychiatry" },
  {
    label: "Dr. Sandeep Govil",
    value: "Dr. Sandeep Govil",
    dept: "Psychiatry",
  },
  {
    label: "Dr. Avina Gupta",
    value: "Dr. Avina Gupta",
    dept: "Psychiatry",
  },
  {
    label: "Dr. Deepika Kohli",
    value: "Dr. Deepika Kohli",
    dept: "Nutritionist",
  },
  { label: "Dr. Niharika Jha", value: "Dr. Niharika Jha", dept: "Dermatology" },
  { label: "Dr. Ridhima", value: "Dr. Ridhima", dept: "Dermatology" },
  {
    label: "Dr. Nitish Singhal",
    value: "Dr. Nitish Singhal",
    dept: "Dermatology",
  },
  { label: "Dr. Javed", value: "Dr. Javed", dept: "Nephrology" },
  { label: "Dr. Ayush Jain", value: "Dr. Ayush Jain", dept: "Surgery" },
  { label: "Dr Ankur Singhal", value: "Dr. Ankur Singhal", dept: "Urology" },
  {
    label: "Dr Niraj Gupta",
    value: "Dr. Niraj Gupta",
    dept: "Paediatric Ortho",
  },
];

export const anardanaOutlets = [
  { label: "Vikas Marg", value: "Vikas Marg" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Sangam", value: "Sangam" },
  { label: "M3M", value: "M3M" },
  { label: "Preet Vihar", value: "Preet Vihar" },
  { label: "Vasant Kunj", value: "Vasant Kunj" },
  { label: "Saket", value: "Saket" },
  { label: "Noida", value: "Noida" },
];

export const littleSumoOutlets = [
  { label: "Preet Vihar", value: "Preet Vihar" },
  { label: "Chandigarh", value: "Chandigarh" },
];

export const farinaOutlets = [
  { label: "Preet Vihar", value: "Preet Vihar" },
  { label: "Chandigarh", value: "Chandigarh" },
];

export const anardanaPhysicalOutlets = [
  { label: "Vikas Marg", value: "Vikas Marg" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Sangam", value: "Sangam" },
  { label: "M3M", value: "M3M" },
  { label: "Saket", value: "Saket" },
  { label: "Noida", value: "Noida" },
];
export const anardanaDeliveryOutlets = [
  { label: "Vasant Kunj", value: "Vasant Kunj" },
  { label: "Saket", value: "Saket" },
  { label: "Preet Vihar", value: "Preet Vihar" },
];
export const radixSource = [
  { label: "Dental Practo", value: "Dental Practo" },
  { label: "Radix Practo", value: "Radix Practo" },
  { label: "Paediatric Practo", value: "Paediatric Practo" },
  { label: "Justdial ", value: "Justdial" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "Organic Calls ", value: "Organic Calls" },
  { label: "Whatsapp Leads", value: "Whatsapp Leads" },
  { label: "Social Media", value: "Social Media" },
];

export const anardanaSource = [
  { label: "Friends", value: "Friends" },
  { label: "Google", value: "Google" },
  { label: "Social Media", value: "Social Media" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "Justdial ", value: "Justdial" },
  { label: "Organic Calls ", value: "Organic Calls" },
];

export const woodappleSource = [
  { label: "Friends", value: "Friends" },
  { label: "Google", value: "Google" },
  { label: "Social Media", value: "Social Media" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "Justdial ", value: "Justdial" },
  { label: "Organic Calls ", value: "Organic Calls" },
];

export const relpSource = [
  { label: "Friends", value: "Friends" },
  { label: "Google", value: "Google" },
  { label: "Social Media", value: "Social Media" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "Justdial ", value: "Justdial" },
  { label: "Organic Calls ", value: "Organic Calls" },
  { label: "Housing", value: "Housing" },
  { label: "99 acres", value: "99 acres" },
];

export const categoryOptions = [
  { label: "Banquet", value: "Banquet" },
  { label: "Room", value: "Room" },
];

export const propertyNameOptions = [
  { label: "Amrapali B-1704", value: "Amrapali B-1704" },
  { label: "Amrapali G-1204", value: "Amrapali G-1204" },
  { label: "Amrapali G-201", value: "Amrapali G-201" },
  { label: "Grand Omaxe", value: "Grand Omaxe" },
  { label: "Palla Plot", value: "Palla Plot" },
  { label: "Ramprastha Plot", value: "Ramprastha Plot" },
];
export const platformOptions = [
  { label: "Zomato ", value: "Zomato" },
  { label: "Google ", value: "Google" },
  { label: "Facebook ", value: "Facebook" },
  { label: "MouthShut ", value: "MouthShut" },
  { label: "Swiggy ", value: "Swiggy" },
  { label: "Practo ", value: "Practo" },
  { label: "Justdial ", value: "Justdial" },
  { label: "Lybrate ", value: "Lybrate" },
];

export const radixPlatformOptions = [
  { label: "Google ", value: "Google" },
  { label: "Facebook ", value: "Facebook" },
  { label: "MouthShut ", value: "MouthShut" },
  { label: "Practo ", value: "Practo" },
  { label: "Justdial ", value: "Justdial" },
  { label: "Lybrate ", value: "Lybrate" },
];
export const anardanaPlatformOptions = [
  { label: "Zomato ", value: "Zomato" },
  { label: "Swiggy ", value: "Swiggy" },
  { label: "Google ", value: "Google" },
  { label: "Facebook ", value: "Facebook" },
  { label: "Dineout ", value: "Dineout" },
  { label: "Easydiner ", value: "Easydiner" },
  { label: "Magicpin", value: "Magicpin" },
  { label: "Tripadvisor ", value: "Tripadvisor" },
  { label: "Justdial ", value: "Justdial" },
];
export const woodapplePlatformOptions = [
  { label: "Google ", value: "Google" },
  { label: "Facebook ", value: "Facebook" },
  { label: "Justdial ", value: "Justdial" },
];
export const greenberryPlatformOptions = [
  { label: "Flipkart ", value: "Flipkart" },
  { label: "Amazon ", value: "Amazon" },
  { label: "Google My Business ", value: "Google My Business" },
];
export const anardanaReview = [
  { label: "Khoob ", value: 5 },
  { label: "Achha ", value: 4 },
  { label: "OK-OK ", value: 2 },
  { label: "BEKAAR ", value: 1 },
];
export const reviewSwitcherOptions = [
  { key: "online", label: "Online", value: "online" },
  { key: "physical", label: "Physical", value: "physical" },
  { key: "delivery", label: "Delivery", value: "delivery" },
];

export const userRoleOptions = [
  { key: "admin", label: "Admin", value: "admin" },
  { key: "user", label: "User", value: "user" },
  { key: "maintainer", label: "maintainer", value: "maintainer" },
];
export const UserOrgOptions = [
  { key: "nebulahq", label: "Nebula HQ", value: "nebulahq" },
  { key: "radix", label: "Radix", value: "radix" },
  { key: "anardana", label: "Anardana", value: "anardana" },
  { key: "woodapple", label: "Woodapple", value: "woodapple" },
  { key: "relp", label: "Relp", value: "relp" },
];
export const greenberryProducts = [
  {
    label: "Bio Active Bright Day Cream with SPF 25 PA+++",
    value: "Bio Active Bright Day Cream with SPF 25 PA+++",
  },
  {
    label: "SPF 50+ plus Sunscreen Gel",
    value: "SPF 50+ plus Sunscreen Gel",
  },
  {
    label: "40+ Sunscreen Spray Lotion",
    value: "40+ Sunscreen Spray Lotion",
  },
  {
    label: "Detox Charcoal Face Wash",
    value: "Detox Charcoal Face Wash",
  },
  {
    label: "Day & Night Lotion ",
    value: "Day & Night Lotion",
  },
  {
    label: "Pure Aloe Vera Gel ",
    value: "Pure Aloe Vera Gel",
  },
];

export const FollowUpModes = [
  { label: "Call", value: "Call" },
  { label: "WhatsApp", value: "WhatsApp" },
  { label: "E-mail", value: "E-mail" },
];

export const leadsStats = {
  statTitle: "Total Leads",
  icon: <Star size={21} />,
  color: "danger",
};
export const closeLeads = {
  statTitle: "Closed Leads",
  icon: <Star size={21} />,
  color: "danger",
};
export const openLeads = {
  statTitle: "Open Leads",
  icon: <Star size={21} />,
  color: "danger",
};

export const reviewsStats = [
  {
    id: 0,
    // title: '230k',
    subtitle: "Positive Review",
    color: "light-success",
    icon: <TrendingUp size={24} />,
  },
  {
    id: 1,
    // title: '1.423k',
    subtitle: "NPS",
    color: "light-info",
    icon: <Compass size={24} />,
  },
  {
    id: 2,
    // title: '8.549k',
    subtitle: "Negative Review",
    color: "light-danger",
    icon: <TrendingDown size={24} />,
  },
];

export const physicalreviewsStats = [
  {
    id: 0,
    // title: '230k',
    subtitle: "Music NPS",
    color: "light-success",
    icon: <Music size={24} />,
  },
  {
    id: 1,
    // title: '1.423k',
    subtitle: "Clean NPS",
    color: "light-warning",
    icon: <Compass size={24} />,
  },
  {
    id: 2,
    // title: '8.549k',
    subtitle: "Food NPS",
    color: "light-danger",
    icon: <Star size={24} />,
  },
  {
    id: 3,
    // title: '230k',
    subtitle: "Service NPS",
    color: "light-secondary",
    icon: <Users size={24} />,
  },
  {
    id: 4,
    // title: '1.423k',
    subtitle: "Place NPS",
    color: "light-info",
    icon: <MapPin size={24} />,
  },
  {
    id: 5,
    // title: '1.423k',
    subtitle: "Beverages NPS",
    color: "light-warning",
    icon: <Coffee size={24} />,
  },
];

export const deliveryreviewsStats = [
  {
    id: 0,
    // title: '230k',
    subtitle: "Order Experience NPS",
    color: "light-success",
    icon: <Music size={24} />,
  },
  {
    id: 1,
    // title: '1.423k',
    subtitle: "Food Quality NPS",
    color: "light-warning",
    icon: <Compass size={24} />,
  },
  {
    id: 2,
    // title: '8.549k',
    subtitle: "Food Packaging NPS",
    color: "light-danger",
    icon: <Package size={24} />,
  },
  {
    id: 3,
    // title: '230k',
    subtitle: "Delivery Time NPS",
    color: "light-secondary",
    icon: <Users size={24} />,
  },
];

// initalState
const initialState ={
    leadData:[],
    physicalReviewData:[],
    reviewData:[],
}

const dashboard = (state= initialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_LEADS':
            return {
                ...state,
                leadData: action.data,
            }
        case 'GET_TOTAL_REVIEWS':
            return {
                ...state,
                reviewData: action.data,
            }
    
        case 'GET_TOTAL_PHYSICAL_REVIEWS':
            return {
                ...state,
                physicalReviewData: action.data,
            }                
        default:
            return { ...state}
    }
}

export default dashboard
import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { getLocalStorage } from '@localStorage';
import { roleAbility } from '@utils'
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
var existingAbility = null
const userData = (getLocalStorage('userData'))
if (userData) {
    const roleItem = roleAbility.find((item) => {
        return item.role === userData.role
    });
    if (roleItem.ability) {
        existingAbility = roleItem.ability
    } else {
        const roleItemType = roleItem.type.find((item) => {
            return item.organization === userData.organization
        });
        existingAbility = roleItemType.ability
    }
}

console.log(existingAbility)
export default new Ability(existingAbility || initialAbility)
